import {Injectable} from '@angular/core';
import {ActionSheetController} from '@ionic/angular';
import {TranslatedService} from '../translated/translated.service';

@Injectable({
    providedIn: 'root'
})
export class ActionSheetService {

    constructor(private translated:TranslatedService,public actionSheetController: ActionSheetController) {
    }

    async presentActionSheet(options) {
        const actionSheet = await this.actionSheetController.create(options);
        await actionSheet.present();
    }
    /**
     * Creating actionSheet Buttons
     * @param text string ->
     * @param role string ->
     * @param icon string - >
     * @param callback function ->
     */
    createBtns(text, role, icon, callback) {
        let obj = {
            'text': this.translated.getTranslatedText(text),
            'role': role,
            'icon': icon,
            handler: () => {
                callback();
            }
        };
        return obj;
    }


}
