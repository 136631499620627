import {Component, OnInit, Renderer2, Input, ViewChild, EventEmitter, Output} from '@angular/core';
import {HelpersService} from '../../services/helpers/helpers.service';

@Component({
  selector: 'app-simple-accordion',
  templateUrl: './simple-accordion.component.html',
  styleUrls: ['./simple-accordion.component.scss'],
})
export class SimpleAccordionComponent implements OnInit {
  accordionExapanded = true;
  @ViewChild("cc") cardContent: any;
  @Input('title') title: string;
  @Input('color') color:string;
  @Input('id') id:string;
  @Output() onFilter: EventEmitter<any> = new EventEmitter();

  icon: string = "arrow-forward";
  constructor(public renderer: Renderer2,public helpers:HelpersService) {
  }
  ngOnInit() {
    this.renderer.setStyle(this.cardContent.el, "webkitTransition", "max-height 500ms, padding 500ms");
  }

  /**
   * Checking where user has clicked
   */
  clickFilter():void {
    if(this.id  == 'verifiers'){
      this.helpers.filter('verifier');
    }else{
      this.helpers.filter('email');
    }

  }

  /**
   * ToggleAccordion
   */
  toggleAccordion() {
    if (this.accordionExapanded) {
      this.renderer.setStyle(this.cardContent.el, "max-height", "0px");
      // this.renderer.setStyle(this.cardContent.el, "padding", "0px 16px");

    } else {
      this.renderer.setStyle(this.cardContent.el, "max-height", "500px");
      // this.renderer.setStyle(this.cardContent.el, "padding", "13px 16px");

    }

    this.accordionExapanded = !this.accordionExapanded;
    this.icon = this.icon == "arrow-forward" ? "arrow-down" : "arrow-forward";

  }

}
