import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HelpersService} from '../helpers/helpers.service';

@Injectable({
    providedIn: 'root'
})
export class AutocompleteService {

    constructor(
        public http: HttpClient,
        public helper: HelpersService
    ) {
    }

    app_id = 'CHn7X44m5yI3m3vGfSaF';
    app_code = 'BQ-LzAMfoEt9G61sg6uxPg';
    type;
    is_regie = false;

    autocompleteLocation(q: any): Observable<any> {
        if (q) {
            q = q.replace(' ', '+');
            // console.log(q);
            let url = 'https://autocomplete.geocoder.api.here.com/6.2/suggest.json?app_id=' + this.app_id +
                '&app_code=' + this.app_code + '&query=' + q + '&beginHighlight=<b>&endHighlight=</b>';

            // let url ="https://maps.googleapis.com/maps/api/place/autocomplete/json?input="+ q +"&types=("+ this.type +")&key=AIzaSyBSlkXte7pFtVNwtdrZJGzy7kftaM6Yx1I";
            return this.http.get(url);
        }
    }

    suggestions: any = [];
    personalSuggestions: any = [];

    show_street = false;
    show_city = false;
    show_zip = false;
    show_country = false;

    show_personal_street = false;
    show_personal_city = false;
    show_personal_zip = false;

    street: any = '';
    city: any = '';
    zip: any = '';
    country:any = '';

    personalStreet: any = '';
    personalCity: any = '';
    personalZip: any = '';



    autocomplete_with_normal_form(event) {

        this.helper.stringToNormalForm(event);

        // console.log(event.srcElement.id);
        if (event.srcElement.id == 'street' || event.srcElement.id == 'strasse') {
            this.type = 'address';
            this.show_street = true;
            this.show_city = false;
            this.show_zip = false;
            this.show_country = false;
            this.autocompleteLocation(this.street).subscribe((res: any) => {
                // console.log(res);
                this.suggestions = res.suggestions;
            });
        }
        if (event.srcElement.id == 'city' || event.srcElement.id == 'ort') {
            this.type = 'cities';
            this.show_street = false;
            this.show_city = true;
            this.show_zip = false;
            this.show_country = false;
            this.autocompleteLocation(this.city).subscribe((res: any) => {
                // console.log(res);
                this.suggestions = res.suggestions;
            });
        }
        if (event.srcElement.id == 'zip') {
            this.type = 'postal_code';
            this.show_street = false;
            this.show_city = false;
            this.show_zip = true;
            this.show_country = false;
            this.autocompleteLocation(this.zip).subscribe((res: any) => {
                // console.log(res);
                this.suggestions = res.suggestions;
            });
        }
        if (event.srcElement.id == 'country') {
            this.type = 'country';
            this.show_street = false;
            this.show_city = false;
            this.show_zip = false;
            this.show_country = true;
            this.autocompleteLocation(this.country).subscribe((res: any) => {
                // console.log(res);
                this.suggestions = res.suggestions;
            });
        }
    }

    hideLists() {
        this.show_street = false;
        this.show_city = false;
        this.show_zip = false;
        this.show_country = false;
    }

    autocompleteSelected(data) {
        // console.log(data);

        setTimeout(() => {

            if(this.is_regie){
                this.street = (data.address.street ? data.address.street.replace(/<[^>]+>/g, '') : (this.street ? this.street : ''));
                this.city = (data.address.postalCode ? data.address.postalCode.replace(/<[^>]+>/g, '') : (this.zip ? this.zip : ''))+ ' ' +(data.address.city ? data.address.city.replace(/<[^>]+>/g, '') : (this.city ? this.city : ''));
            }
            else{

                this.street = (data.address.street ? data.address.street.replace(/<[^>]+>/g, '') : (this.street ? this.street : ''));
                this.city = (data.address.city ? data.address.city.replace(/<[^>]+>/g, '') : (this.city ? this.city : ''));
                this.zip = (data.address.postalCode ? data.address.postalCode.replace(/<[^>]+>/g, '') : (this.zip ? this.zip : ''));
                this.country = (data.address.country ? data.address.country.replace(/<[^>]+>/g, '') : (this.country ? this.country : ''));
            }
        }, 1000);

        this.hideLists();
    }

    resetValues() {
        this.city = '';
        this.zip = '';
        this.street = '';
        this.country = '';
        this.is_regie = false;
    }

    autocomplete_with_normal_form_personal(event) {

        this.helper.stringToNormalForm(event);

        // console.log(event.srcElement.id);
        if (event.srcElement.id == 'personal_street' || event.srcElement.id == 'a_strasse') {
            this.type = 'address';
            this.show_personal_street = true;
            this.show_personal_city = false;
            this.show_personal_zip = false;

            this.autocompleteLocation(this.personalStreet).subscribe((res: any) => {
                // console.log(res);
                this.personalSuggestions = res.suggestions;
            });
        }
        if (event.srcElement.id == 'personal_city' || event.srcElement.id == 'a_ort') {
            this.type = 'cities';
            this.show_personal_street = false;
            this.show_personal_city = true;
            this.show_personal_zip = false;
            this.autocompleteLocation(this.personalCity).subscribe((res: any) => {
                // console.log(res);
                this.personalSuggestions = res.suggestions;
            });
        }
        if (event.srcElement.id == 'personal_zip') {
            this.type = 'postal_code';
            this.show_personal_street = false;
            this.show_personal_city = false;
            this.show_personal_zip = true;
            this.autocompleteLocation(this.personalZip).subscribe((res: any) => {
                // console.log(res);
                this.personalSuggestions = res.suggestions;
            });
        }
    }

    hideListsPersonal() {
        this.show_personal_street = false;
        this.show_personal_city = false;
        this.show_personal_zip = false;
    }

    autocompleteSelectedPersonal(data) {
        // console.log(data);

        setTimeout(() => {

            if (this.is_regie) {
                this.personalStreet = (data.address.street ? data.address.street.replace(/<[^>]+>/g, '') : (this.personalStreet ? this.personalStreet : ''));
                this.personalCity =(data.address.postalCode ? data.address.postalCode.replace(/<[^>]+>/g, '') : (this.personalZip ? this.personalZip : ''))+ ' ' + (data.address.city ? data.address.city.replace(/<[^>]+>/g, '') : (this.personalCity ? this.personalCity : ''));
            } else {

                this.personalStreet = (data.address.street ? data.address.street.replace(/<[^>]+>/g, '') : (this.personalStreet ? this.personalStreet : ''));
                this.personalCity = (data.address.city ? data.address.city.replace(/<[^>]+>/g, '') : (this.personalCity ? this.personalCity : ''));
                this.personalZip = (data.address.postalCode ? data.address.postalCode.replace(/<[^>]+>/g, '') : (this.personalZip ? this.personalZip : ''));

            }
        }, 1000);

        this.hideListsPersonal();
    }

    resetValuesPersonal() {
        this.personalStreet = '';
        this.personalCity = '';
        this.personalZip = '';
        this.is_regie = false;
    }
}
