import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {SimpleAccordionComponent} from './simple-accordion.component';


@NgModule({
  declarations: [
      SimpleAccordionComponent
  ],
  entryComponents: [
    SimpleAccordionComponent
  ],
  exports: [
    SimpleAccordionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  providers: [

  ]
})
export class SimpleAccordionModule {}
