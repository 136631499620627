import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenService} from '../authentication/token.service';
import {LanguageService} from '../language/language.service';
import {Observable} from 'rxjs';
import {HelpersService} from '../helpers/helpers.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

    constructor(public http: HttpClient,public tokenProvider:TokenService, public lngProvider:LanguageService,public helpers:HelpersService) {}

    getClientList(): Observable<any> {
        let headers = new HttpHeaders().set("Authorization",this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/client/list', {headers: headers});
    }

    getClient(id): Observable<any> {
        let headers = new HttpHeaders().set("Authorization",this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/client/edit/' + id, {headers: headers});
    }

    addClient(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(this.helpers.url+this.lngProvider.language+'/api/company/client/add', JSON.stringify(data),{headers: headers});
    }

    editClient(id,data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(this.helpers.url+this.lngProvider.language+'/api/company/client/edit/' + id, JSON.stringify(data), {headers: headers});
    }
    getTotalHours(id): Observable<any> {
        let headers = new HttpHeaders().set("Authorization",this.tokenProvider.returnToken());
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/client/total/hours/' + id, {headers: headers});
    }

// CONTACT API

    addContact(id,data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(this.helpers.url+this.lngProvider.language+'/api/company/client/add/contact/' + id,JSON.stringify(data),{headers:headers});
    }

    getContactList(id): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/client/details/contacts/' + id, {headers: headers});
    }

    sendQrCode(id): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/client/send/contact/code/' + id, {headers: headers});
    }

    getContact(id): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.helpers.url+this.lngProvider.language+'/api/company/client/edit/contact/' + id, {headers: headers});
    }

    editContact(id,data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(this.helpers.url+this.lngProvider.language+'/api/company/client/edit/contact/' + id,JSON.stringify(data),{headers: headers});
    }

    deleteContact(id): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization',this.tokenProvider.returnToken());
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.delete(this.helpers.url+this.lngProvider.language+'/api/company/client/delete/contact/' + id, {headers: headers});
    }
}
