import { Injectable } from '@angular/core';
import {DragulaService} from 'ng2-dragula';

@Injectable({
  providedIn: 'root'
})
export class DraggableService {

  constructor( private dragulaService: DragulaService) {


  }

  /**
   * Draggable function enables draggable
   * @param groupName string-> the object that we want to move
   */
  draggable(groupName){
    return this.dragulaService.drag(groupName)

  }

  /**
   * Dropable function
   * @param groupName string-> the object that we want to move
   */
  dropable(groupName){
    this.dragulaService.dropModel(groupName)
  }

  /**
   * Create Group Function
   * @param groupName string-> the object that we want to move
   */
  createGroup(groupName){
    this.dragulaService.createGroup(groupName, {
      removeOnSpill: true
    });
  }

}
